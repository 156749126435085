import React from "react"
import SEO from "../components/seo"
import Loadable from 'react-loadable';
import Loader from '../components/global/loader'
import Fade from 'react-reveal/Fade';

const Home = Loadable({
  loader: () => import('../components/homeloading'),
  loading: Loader,
});




const IndexPage = () => (


  

  <div className="p-0 m-0" style={{backgroundColor:"black"}}>
  
<SEO title="Home" />
<div style={{backgroundColor:"black"}}>
<Fade duration={1000}  center >
<Home/>

</Fade> 
</div>
</div>  
)



export default IndexPage
